import {FC, useContext} from "react";
import {AuthContext} from "../../context/auth";

interface CanProps {
  roles: string[];
}

// @ts-ignore - @fixme: Please fix this ts-ignore
const Can: FC<CanProps> = (props) => {
  const {hasRole} = useContext(AuthContext);

  return (hasRole && hasRole(props.roles)) ? props.children : null;
};

export default Can;
