import React, { FC, useEffect, useState } from "react";
import { Box, CircularProgress, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { SuccessColor } from "../../theme";
import useRack from "../../hooks/useRack";
import { getHeaders } from "../../lib/api";
import { getSnackbarSettings, handleFetch } from "../../lib/utils";
import { useSnackbar } from "notistack";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3, 2),
    },
    icon: {
      marginRight: theme.spacing(2),
      fontSize: "36px",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    paperError: {
      color: "#fff",
      backgroundColor: theme.palette.error.main,
    },
    paperSuccess: {
      color: "#fff",
      backgroundColor: SuccessColor,
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  })
);

const Clear: FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { rack } = useRack();

  useEffect(() => {
    const clearRack = async () => {
      if (rack) {
        setLoading(true);

        try {
          const { REACT_APP_API_URL } = process.env;
          const response = await fetch(`${REACT_APP_API_URL}/boards/clear`, {
            method: "POST",
            body: JSON.stringify({
              rackcode: rack,
            }),
            headers: getHeaders(),
          });

          await handleFetch(response);
          enqueueSnackbar(`Vak: ${rack} is leeg`, getSnackbarSettings("success"));
        } catch (e) {
          console.log(e);

          enqueueSnackbar(`Er is iets misgegaan tijdens het legen van vak ${rack}`, getSnackbarSettings("error"));
        }

        setLoading(false);
      }
    };

    clearRack();
  }, [rack]);

  return (
    <>
      <Helmet>
        <title>Reststuk inscannen - {process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>

      <Paper className={classes.paper}>
        <Typography align="center" variant="h4">
          {loading ? `Vak ${rack} aan het legen...` : "Scan vak om te legen"}
        </Typography>
      </Paper>

      {/* {loading && (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      )}

      {error && <ErrorPaper />}

      {!error && <SuccessPaper />} */}
    </>
  );
};

export default Clear;
