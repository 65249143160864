import { Container, createStyles, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: "100%",
    },
    paper: {
      padding: theme.spacing(3, 2),
    },
    label: {
      display: "none",
    },
  })
);

const DIKTE_AFSTANDRAND = 100;
const OVERMAAT_MATERIAAL = 2;
const DIKTE_ZAAGBLAD = 4.4;

const Verstek: FC = () => {
  const classes = useStyles();
  const [angle, setAngle] = useState(45);
  const [thickness, setThickness] = useState(18);
  const [result, setResult] = useState(0);

  useEffect(() => {
    const calc =
      DIKTE_AFSTANDRAND +
      OVERMAAT_MATERIAAL +
      thickness / Math.tan(((90 - angle) / 180) * Math.PI) -
      Math.sqrt(Math.pow(DIKTE_ZAAGBLAD / Math.tan(((90 - angle) / 180) * Math.PI), 2) + Math.pow(DIKTE_ZAAGBLAD, 2));

    setResult(Number(calc.toFixed(2)));
  }, [angle, thickness]);

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper}>
        <Typography variant="h6" component="h1">
          Verstek overmaat
        </Typography>
        <TextField
          id="thickness"
          name="thickness"
          label="Dikte in mm"
          value={thickness}
          onChange={(e) => setThickness(Number(e.target.value))}
          type="number"
          className={classes.textField}
          variant="outlined"
          margin="normal"
          required={true}
        />
        <TextField
          id="angle"
          name="angle"
          label="Hoek in °"
          value={angle}
          onChange={(e) => setAngle(Number(e.target.value))}
          type="number"
          className={classes.textField}
          variant="outlined"
          margin="normal"
          required={true}
        />

        <TextField
          id="result"
          name="result"
          label="Afstand geleider"
          value={result}
          type="number"
          className={classes.textField}
          variant="outlined"
          margin="normal"
          disabled={true}
        />
      </Paper>
    </Container>
  );
};

export default Verstek;
