import {createMuiTheme} from "@material-ui/core/styles";


/* I don't know how to modify the Material UI theme to add custom properties, so I added a constant instead. */
export const SuccessColor = "#43a047";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#9AC8DB",
      contrastText: "#FFFFFF"
    },
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  }
});

export default theme;
