import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import AppFrame from "./components/AppFrame";
import theme from "./theme";
import PrivateRoute from "./components/PrivateRoute";
import { BoardRoles, GroupRoles, MaterialRoles, UserRoles } from "./services/roles";
import Cnc from "./views/Production/Cnc";
import Verstek from "./views/Production/Verstek";
import Clear from "./views/Boards/Clear";

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ "./views/Dashboard"));
const Materials = lazy(() => import(/* webpackChunkName: "Materials" */ "./views/Materials"));
const MaterialsDetail = lazy(() => import(/* webpackChunkName: "Materials/Detail" */ "./views/Materials/Detail"));
const AddBoards = lazy(() => import(/* webpackChunkName: "Boards/Add" */ "./views/Boards/Add"));
const ReAdd = lazy(() => import(/* webpackChunkName: "Boards/ReAdd" */ "./views/Boards/ReAdd"));
const ScanBoards = lazy(() => import(/* webpackChunkName: "Boards/Scan" */ "./views/Boards/Scan"));
const UseBoards = lazy(() => import(/* webpackChunkName: "Boards/Use" */ "./views/Boards/Use"));
const ListBoards = lazy(() => import(/* webpackChunkName: "Boards/List" */ "./views/Boards/List"));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ "./views/Login"));
const Users = lazy(() => import(/* webpackChunkName: "Users" */ "./views/Users"));
const UsersPermissions = lazy(() => import(/* webpackChunkName: "Users/Permissions" */ "./views/Users/Permissions"));
const Error = lazy(() => import(/* webpackChunkName: "Error" */ "./views/Error"));

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <HelmetProvider>
          <CssBaseline />

          <BrowserRouter>
            <AppFrame>
              <Suspense fallback={<div>loading...</div>}>
                <Switch>
                  <PrivateRoute path="/" exact={true}>
                    <Dashboard />
                  </PrivateRoute>

                  <PrivateRoute path="/production/cnc" exact={true} roles={[BoardRoles.Create]}>
                    <Cnc />
                  </PrivateRoute>

                  <PrivateRoute path="/production/verstek" exact={true} roles={[BoardRoles.Create]}>
                    <Verstek />
                  </PrivateRoute>

                  <PrivateRoute path="/materials" exact={true} roles={[MaterialRoles.Get]}>
                    <Materials />
                  </PrivateRoute>

                  <PrivateRoute path="/materials/:id" exact={true} roles={[MaterialRoles.Get]}>
                    <MaterialsDetail />
                  </PrivateRoute>

                  <PrivateRoute path="/boards/add" exact={true} roles={[BoardRoles.Create]}>
                    <AddBoards />
                  </PrivateRoute>

                  <PrivateRoute path="/boards/readd" exact={true} roles={[BoardRoles.Create]}>
                    <ReAdd />
                  </PrivateRoute>

                  <PrivateRoute path="/boards/scan" exact={true} roles={[BoardRoles.Create]}>
                    <ScanBoards />
                  </PrivateRoute>

                  <PrivateRoute path="/boards/use" exact={true} roles={[BoardRoles.Create]}>
                    <UseBoards />
                  </PrivateRoute>

                  <PrivateRoute path="/boards/clear" exact={true} roles={[GroupRoles.Get]}>
                    <Clear />
                  </PrivateRoute>

                  <PrivateRoute path="/boards/list" exact={true} roles={[BoardRoles.Create]}>
                    <ListBoards />
                  </PrivateRoute>

                  <PrivateRoute path="/users" exact={true} roles={[UserRoles.Get]}>
                    <Users />
                  </PrivateRoute>

                  <PrivateRoute path="/users/permissions" exact={true} roles={[GroupRoles.Get]}>
                    <UsersPermissions />
                  </PrivateRoute>

                  <Route path="/login" exact={true}>
                    <Login />
                  </Route>

                  <Route>
                    <Error message="404" />
                  </Route>
                </Switch>
              </Suspense>
            </AppFrame>
          </BrowserRouter>
        </HelmetProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
