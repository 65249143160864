import React, {FC, useContext} from "react";
import {Redirect, Route, RouteProps} from "react-router";
import {AuthContext} from "../../context/auth";
import Error from "../../views/Error";

interface PrivateRouteProps extends RouteProps {
  roles?: string[]
}

const PrivateRoute: FC<PrivateRouteProps> = ({children, roles, ...rest}) => {
  const {authenticated, hasRole} = useContext(AuthContext);

  return <Route {...rest} render={props => {
    const {location} = props;

    if (!authenticated) {
      // if the user is not logged in, redirect the user to the login page
      return <Redirect to={{pathname: "/login", state: {from: location}}}/>
    }

    if (hasRole && roles && !hasRole(roles)) {
      // if the user has none of the specified roles, display an error component
      return <Error message="403"/>
    }

    return children
  }
  }/>
};

export default PrivateRoute;
