export enum MaterialRoles {
  Get    = "ROLE_MATERIALS_GET",
  Create = "ROLE_MATERIALS_CREATE",
  Update = "ROLE_MATERIALS_UPDATE",
  Delete = "ROLE_MATERIALS_DELETE",
}

export enum BoardRoles {
  Get    = "ROLE_BOARDS_GET",
  Create = "ROLE_BOARDS_CREATE",
  Update = "ROLE_BOARDS_UPDATE",
  Delete = "ROLE_BOARDS_DELETE",
}

export enum UserRoles {
  Get    = "ROLE_USERS_GET",
  Create = "ROLE_USERS_CREATE",
  Update = "ROLE_USERS_UPDATE",
  Delete = "ROLE_USERS_DELETE",
}

export enum GroupRoles {
  Get    = "ROLE_GROUPS_GET",
  Create = "ROLE_GROUPS_CREATE",
  Update = "ROLE_GROUPS_UPDATE",
  Delete = "ROLE_GROUPS_DELETE",
}

export enum JobRoles {
  Get    = "ROLE_JOBS_GET",
  Create = "ROLE_JOBS_CREATE",
  Update = "ROLE_JOBS_UPDATE",
  Delete = "ROLE_JOBS_DELETE",
}
