import { useEffect, useState } from "react";
import useScanner from "./useScanner";

const useRack = () => {
  const { result } = useScanner();
  const [rack, setRack] = useState<string>();

  useEffect(() => {
    const isRackTest = new RegExp("^[A-Za-z]+.*[0-9]+$");

    if (result !== rack && isRackTest.test(result)) {
      setRack(result);
    }
  }, [rack, result]);

  return { rack };
};

export default useRack;
