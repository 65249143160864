import React, { FC, SyntheticEvent, useContext, useState } from "react";
import { Collapse, createStyles, Divider, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from "@material-ui/core";
import ListItemLink from "../ListItemLink";
import Can from "../Can";
import { BoardRoles, GroupRoles, MaterialRoles, UserRoles } from "../../services/roles";
import { AuthContext } from "../../context/auth";

import ListAltIcon from "@material-ui/icons/ListAlt";
import CameraAlt from "@material-ui/icons/CameraAlt";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import PrintIcon from "@material-ui/icons/Print";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EjectIcon from "@material-ui/icons/Eject";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import PeopleIcon from "@material-ui/icons/People";
import LockIcon from "@material-ui/icons/Lock";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import GradeIcon from "@material-ui/icons/Grade";
import DialpadIcon from "@material-ui/icons/Dialpad";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      [theme.breakpoints.up("md")]: {
        marginTop: 64,
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const Drawer: FC = () => {
  const classes = useStyles();
  const { setToken, body } = useContext(AuthContext);
  const [listState, setListState] = useState({ boards: true, production: false, admin: false });

  const toggleList = (event: SyntheticEvent, type: string, state: boolean) => {
    event.stopPropagation();

    setListState({ ...listState, [type]: state });
  };

  return (
    <div className={classes.list}>
      <List>
        <Can roles={[MaterialRoles.Get]}>
          <ListItemLink to="/materials" primary="Materialen" icon={<ListAltIcon />} />
        </Can>

        <Can roles={[BoardRoles.Create]}>
          <ListItem button onClick={(e) => toggleList(e, "boards", !listState["boards"])}>
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>
            <ListItemText primary="Reststukken" />
            {listState.boards ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={listState.boards} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemLink className={classes.nested} to="/boards/add" primary="Labels maken" icon={<PrintIcon />} />
              <ListItemLink className={classes.nested} to="/boards/readd" primary="Overstickeren" icon={<PrintIcon />} />
              <ListItemLink className={classes.nested} to="/boards/list" primary="Zoeken" icon={<SearchIcon />} />
              <Hidden smUp>
                <ListItemLink className={classes.nested} to="/boards/scan" primary="Inscannen" icon={<CameraAlt />} />
                <ListItemLink className={classes.nested} to="/boards/use" primary="Gebruiken" icon={<EjectIcon />} />
                <ListItemLink className={classes.nested} to="/boards/clear" primary="Vak Legen" icon={<EjectIcon />} />
              </Hidden>
            </List>
          </Collapse>
        </Can>

        <Can roles={[BoardRoles.Create]}>
          <ListItem button onClick={(e) => toggleList(e, "production", !listState["production"])}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary="Productie" />
            {listState.production ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={listState.production} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Hidden smUp>
                <ListItemLink className={classes.nested} to="/production/cnc" primary="CNC" icon={<GradeIcon />} />
              </Hidden>
              <ListItemLink className={classes.nested} to="/production/verstek" primary="Verstek berekenen" icon={<DialpadIcon />} />
            </List>
          </Collapse>
        </Can>

        <Can roles={[UserRoles.Get, GroupRoles.Get]}>
          <ListItem button onClick={(e) => toggleList(e, "admin", !listState["admin"])}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Beheer" />
            {listState.admin ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={listState.admin} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Can roles={[UserRoles.Get]}>
                <ListItemLink className={classes.nested} to="/users" primary="Gebruikers" icon={<PeopleIcon />} />
              </Can>
              <Can roles={[GroupRoles.Get]}>
                <ListItemLink className={classes.nested} to="/users/permissions" primary="Rechten" icon={<LockIcon />} />
              </Can>
            </List>
          </Collapse>
        </Can>

        {body && (
          <>
            <Divider />
            <ListItem
              button
              onClick={() => {
                if (setToken) setToken("");
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>

              <ListItemText primary="Uitloggen" secondary={`Ingelogd als: ${body.firstname}`} />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export default Drawer;
