import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';
import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./context/auth";

Sentry.init({dsn: "https://f05dadf3ddeb40beb3382ec3cbfb43ca@sentry.io/5167224"});

ReactDOM.render(
  <AuthProvider>
    <App/>
  </AuthProvider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
