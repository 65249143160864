import React, { FC, useContext, useState } from "react";
import { AppBar, Drawer, Hidden, IconButton, Toolbar, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

import CustomDrawer from "./Drawer";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { AuthContext } from "../../context/auth";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    logo: {
      fill: "#fff",
      maxHeight: 25,
      width: "auto",
      marginLeft: theme.spacing(1.5),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: {
      margin: theme.spacing(6),
    },
    popover: {
      padding: theme.spacing(2),
    },
    menuButtonRight: {
      marginLeft: "auto",
    },
  })
);

const AppFrame: FC = (props) => {
  const classes = useStyles();
  const { authenticated } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {authenticated && (
            <IconButton color="inherit" onClick={handleDrawerToggle} className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          )}

          <Hidden xsDown>
            <Typography variant="h6" noWrap>
              Warehouse Management
            </Typography>

            <Logo className={classes.logo} />
          </Hidden>
        </Toolbar>
      </AppBar>

      {authenticated && (
        <nav>
          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <CustomDrawer />
            </Drawer>
          </Hidden>

          <Hidden smDown implementation="js">
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <CustomDrawer />
            </Drawer>
          </Hidden>
        </nav>
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

export default AppFrame;
