export enum VibrationType {
  SUCCESS,
  ERROR,
  SCAN
}

export const vibrate = (type: VibrationType = VibrationType.SUCCESS) => {
  if (window.navigator.vibrate) {
    let pattern: number | number[];

    switch (type) {
      case VibrationType.SUCCESS:
        pattern = [200, 0, 200];
        break;
      case VibrationType.SCAN:
        pattern = 100;
        break;
      default:
        pattern = 2000;
        break;
    }

    window.navigator.vibrate(pattern);
  }
};