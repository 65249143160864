import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

interface ErrorProps {
  message: string;
}

const Error: FC<ErrorProps> = ({ message }) => {
  return (
    <>
      <Helmet>
        <title>Error - {process.env.REACT_APP_SITE_TITLE}</title>
      </Helmet>

      <Box alignItems="center" height="100%">
        <Typography align="center" variant="h1">{message}</Typography>
      </Box>
    </>
  );
};

export default Error;
