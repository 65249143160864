interface Violations {
  propertyPath: string;
  message: string;
}

class FetchError extends Error {
  public violations: Violations[];

  constructor(message: string, violations: Violations[]) {
    super(message);

    this.violations = violations;
    this.message    = violations.map(violation => `${violation.propertyPath}: ${violation.message.toLowerCase()}`).join("<br/>");
  }
}

export default FetchError;
