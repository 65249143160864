import { useEffect, useRef, useState } from "react";
import { vibrate, VibrationType } from "../lib/utils/vibrate";

const useScanner = () => {
  const [result, setResult] = useState<string>("");
  const temp = useRef<string>("");

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const { key } = e;
      const disallowedKeys = ["Shift", "Enter", "Unidentified"];

      if (disallowedKeys.indexOf(key) === -1) {
        temp.current += key;

        if (temp.current.slice(-3) === "<--") {
          setResult(temp.current.slice(3, -3));
          temp.current = "";

          vibrate(VibrationType.SCAN);
        }
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [result]);

  return { result, setResult };
};

export default useScanner;
