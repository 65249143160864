import React, { FC } from "react";
import s from "./ScanPlaceholder.module.css";
import { ReactComponent as QRPlaceholder } from "../../assets/img/placeholder.svg";

const ScanPlaceholder: FC = () => {
  return (
    <div className={s.root}>
      <div className={s.line} />
      <div className={s.container}>
        <QRPlaceholder />
      </div>
    </div>
  );
};

export default ScanPlaceholder;
