class CreateError extends Error {
  public shortMessage: string;

  constructor(message: string, shortMessage: string) {
    super(message);

    this.shortMessage = shortMessage;
  }
}

export default CreateError;
