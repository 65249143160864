import {handleFetch} from "./utils";

const {REACT_APP_API_URL} = process.env;
type generic = Record<string, any>;

export interface Filter {
  property: string;
  value: string;
}

export interface Order {
  property: string;
  value: string;
}

export const getHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${window.localStorage.getItem('token')}`
});

export const getItems = async <T = generic>(type: string, page: number = 1, itemsPerPage: number = 30, filters: Filter[] = [], order?: Order): Promise<T> => {
  const url = new URL(`${REACT_APP_API_URL}/${type}`);

  url.searchParams.append('itemsPerPage', itemsPerPage.toString());
  url.searchParams.append('page', page.toString());

  filters.forEach(filter => url.searchParams.append(filter.property, filter.value));

  if (order) {
    url.searchParams.append(`order[${order.property}]`, order.value);
  }

  const response = await fetch(url.href, {method: "GET", headers: getHeaders()});

  return await handleFetch(response);
};

export const getItem = async <T = generic>(type: string, id: number): Promise<T> => {
  const response = await fetch(`${REACT_APP_API_URL}/${type}/${id}`, {method: "GET", headers: getHeaders()});

  return await handleFetch(response);
};

export const deleteItem = async (type: string, id: number): Promise<void> => {
  await fetch(`${REACT_APP_API_URL}/${type}/${id}`, {method: "DELETE", headers: getHeaders()});

  return;
};

export const postItem = async <T = generic>(type: string, data: T) => {
  const response = await fetch(`${REACT_APP_API_URL}/${type}`, {
    method: "POST",
    body: JSON.stringify(normalize(data)),
    headers: getHeaders()
  });

  return await handleFetch(response);
};

export const updateItem = async <T>(type: string, id: number, data: T) => {
  const response = await fetch(`${REACT_APP_API_URL}/${type}/${id}`, {
    method: "PUT",
    body: JSON.stringify(normalize(data)),
    headers: getHeaders()
  });

  return await handleFetch(response);
};

export const patchItem = async <T>(type: string, id: number, data: Record<string, any>): Promise<T> => {
  const response = await fetch(`${REACT_APP_API_URL}/${type}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(normalize(data)),
    headers: {...getHeaders(), 'Content-Type': 'application/merge-patch+json'}
  });

  return await handleFetch(response);
};


const normalize = (data: Record<any, any>) => {
  const object: Record<any, any> = {};

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'positions') {
      return;
    } else if (value === Object(value)) {
      value = normalize(value);
    } else if (value === '') {
      return;
    } else if (!Number.isNaN(Number(value)) && key !== 'barcode') {
      value = Number(value);
    }

    object[key] = value;
  });

  return object;
};
