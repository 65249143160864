import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { IconButton, Grid, Typography, Button, TextField, SwipeableDrawer, ListItem, List, ListItemText, ListSubheader, Divider } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardIcon from "@material-ui/icons/Keyboard";

import useScanner from "../../hooks/useScanner";
import ScanPlaceholder from "../../components/ScanPlaceholder/ScanPlaceholder";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { handleFetch } from "../../lib/utils";

const useCncScanResult = () => {
  const { result, setResult } = useScanner();
  const [item, setItem] = useState<string>();
  const [data, setData] = useState<Record<string, any>>();
  const [error, setError] = useState<Error>();

  const fetchItem = async (item: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/production/cnc/item/${item}`);

    return await handleFetch(response);
  };

  useEffect(() => {
    if (item) {
      setData(undefined);
      setError(undefined);

      fetchItem(item)
        .then((data) => setData(data))
        .catch((error) => setError(error));

      setItem(undefined);
      setResult(undefined);
    }
  }, [item, setResult]);

  useEffect(() => {
    try {
      const decoded = JSON.parse(result);

      setItem(decoded?.item);
    } catch (e) {
      setItem(undefined);
      setResult(undefined);
    }
  }, [result, setResult]);

  return { data, error, setItem, setResult };
};

const ManualForm: FC<{ setItem: React.Dispatch<React.SetStateAction<string>> }> = ({ setItem }) => {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const { handleSubmit, control } = useForm();

  const onKeyboardSubmit = (data) => {
    setItem(data.item);
  };

  return (
    <>
      <Grid container justify="center">
        <IconButton aria-label="enter" onClick={() => setShowKeyboard(!showKeyboard)}>
          <KeyboardIcon fontSize="large" />
        </IconButton>
      </Grid>

      {showKeyboard && (
        <form onSubmit={handleSubmit(onKeyboardSubmit)}>
          <Grid container justify="center" spacing={1}>
            <Grid item xs={9}>
              <Controller as={TextField} control={control} variant="outlined" name="item" defaultValue="" label="Item Nummer" />
            </Grid>
            <Grid item xs={3}>
              <Button aria-label="enter" type="submit" variant="contained" disableElevation style={{ height: "100%" }}>
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

const Cnc: FC = () => {
  const { data, error, setItem, setResult } = useCncScanResult();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const toggleDrawer = (open: boolean) => (event: SyntheticEvent<{}, KeyboardEvent>) => {
    if (open === false) {
      setItem(undefined);
      setResult(undefined);
    }

    // @ts-ignore
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setDrawerOpen(open);
  };

  useEffect(() => {
    if (data !== undefined && !error) {
      enqueueSnackbar("Verstuurd naar CNC machine", {
        variant: "success",
      });

      setDrawerOpen(true);
    }

    if (error) {
      enqueueSnackbar("Fout tijdens ophalen van item. Item is mogelijk niet geschikt voor CNC", {
        variant: "error",
      });

      setDrawerOpen(false);
    }
  }, [data, error, enqueueSnackbar]);

  return (
    <>
      <Typography>
        <b>Scan de QR code op de sticker van het item</b>
      </Typography>
      <Grid container justify="center">
        <ScanPlaceholder />
      </Grid>

      <ManualForm setItem={setItem} />

      <SwipeableDrawer disableDiscovery open={drawerOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} anchor="bottom">
        <div style={{ width: "auto", maxHeight: "80vh" }}>
          <List dense>
            <ListSubheader disableSticky>Iteminformatie</ListSubheader>
            <ListItem>
              <ListItemText primary="Materiaal" secondary={data?.item?.name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Vorm" secondary={data?.item?.shape} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Afmetingen" secondary={data?.item?.dimensions} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListSubheader disableSticky>Orderinformatie</ListSubheader>
            <ListItem>
              <ListItemText primary="Ordernummer" secondary={data?.order?.incrementId} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Klantnaam" secondary={data?.order?.customerName} />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default Cnc;
