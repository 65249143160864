import {Query} from "material-table";
import {Filter, Order} from "../api";
import FetchError from "./Errors/FetchError";
import {OptionsObject, VariantType} from "notistack";
import CreateError from "./Errors/CreateError";

export const handleFetch = async (response: Response): Promise<any> => {
  const json = await response.json();

  if (response.status === 401) {
    window.location.href = '/login';
  } else if (!response.ok && json.hasOwnProperty("violations")) {
    throw new FetchError(response.statusText, json.violations);
  } else if (!response.ok && json.hasOwnProperty("hydra:description")) {
    throw new CreateError(json["hydra:description"], json["hydra:title"] || "");
  } else if (!response.ok && json.hasOwnProperty("message")) {
    throw new Error(json.message);
  }

  return json;
};

export const transformApiToQueryResult = async <T extends {}>(data: Promise<any>, query: Query<T>) => {
  const response = await data;
  let items      = [];
  let totalItems = 0;

  if (response && Object.prototype.hasOwnProperty.call(response, "hydra:member")) {
    items = response["hydra:member"].map((item: Record<string, any>) => {
      Object.entries(item).forEach(([key, value]) => {
        if (['createdAt', 'usedAt', 'updatedAt'].indexOf(key) !== -1) {
          item[key] = new Date(value as string);
        }
      });

      return item;
    });

    totalItems = response["hydra:totalItems"];
  }

  return {
    data: items,
    page: query.page,
    totalCount: totalItems
  };
};

export const getPage = <T extends {}>(query: Query<T>): number => {
  return query.page + 1;
};

export const getFilters = <T extends {}>(query: Query<T>): Filter[] => {
  return query.filters.map(filter => {
    const property = filter.column.field as string;

    return {property, value: filter.value}
  });
};

export const getOrder = <T extends {}>(query: Query<T>): Order | undefined => {
  if (query.orderBy) {
    const property = query.orderBy.field as string;

    return {property, value: query.orderDirection}
  }

  return undefined;
};

export function getSnackbarSettings(variant: VariantType): OptionsObject {
  return {
    variant: variant,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    }
  }
}
