import React, { createContext, FC, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

type ContextProps = {
  token: string;
  setToken: (token: string) => void;
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  body: Body;
  hasRole: (roles: string[]) => boolean;
};

interface Body {
  exp: number;
  iat: number;
  roles: string[];
  username: string;
  firstname: string;
  lastname: string;
}

export const AuthContext = createContext<Partial<ContextProps>>({});

const AuthProvider: FC = ({ children }) => {
  const prevAuth = window.localStorage.getItem("token") || "";
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const [token, setToken] = useState<string>(prevAuth);
  const [body, setBody] = useState<Body>();
  const timestamp = Number((new Date().getTime() / 1000).toFixed(0));

  const hasRole = (roles: string[]): boolean => {
    if (body) {
      return undefined !== roles.find((role) => body.roles.includes(role));
    }

    return false;
  };

  useEffect(() => {
    try {
      const decoded: Body = jwtDecode(token);

      if (!Array.isArray(decoded.roles)) {
        decoded.roles = Object.values(decoded.roles);
      }

      if (decoded.exp <= timestamp) {
        setToken("");
        setAuthenticated(false);
      }

      setBody(decoded);
      setAuthenticated(true);
    } catch (e) {
      setAuthenticated(false);
      setBody(undefined);
    }

    window.localStorage.setItem("token", token);
  }, [token, authenticated, timestamp]);

  const defaultContext = {
    token,
    setToken,
    authenticated,
    setAuthenticated,
    body,
    hasRole,
  };

  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
