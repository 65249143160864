import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

interface Props {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  className?: string
}


const ListItemLink: React.FC<Props> = props => {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "innerRef" | "to">>((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} innerRef={ref}/>
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className || ''}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary}/>
      </ListItem>
    </li>
  );
};

export default ListItemLink;
